import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'av-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
})
export class FrameComponent implements OnInit {
  constructor(private auth: AuthService, private router: Router) {
    router.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) =>
     {
        this.currentRoute = event.url;
     });
  }

  @Input()  stretch = true;
  currentRoute: string;
  tokenInfo = this.auth.getTokenInfo();

  ngOnInit() {}

  logout() {
    this.auth.logout();
  }
}
