import { Query } from '@datorama/akita';
import { Inject, Injectable } from '@angular/core';
import { SessionState } from './session.model';
import { SessionStore } from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  constructor(@Inject(SessionStore) protected store: SessionStore) {
    super(store);
  }

  user$ = this.select((state) => state.user);
}
