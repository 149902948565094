<div class="meridians">
  <ul *ngIf="showList">
    <li *ngFor="let meridian of meridians" (click)="onSelect(meridian)">
      <input type="checkbox" [checked]="meridian.active"/>
      {{meridian.name}}
    </li>
  </ul>
  <div class="meridians-toggle" (click)="toggle()">
    <span>Meridians({{totalActive}})</span>
    <av-chevron-toggle [expanded]="showList"></av-chevron-toggle>
  </div>
</div>