<form action="" [formGroup]="form" class="point-edit">
  <div class="form-row">
    <av-keywords [keywords]="meridians" placeholder="Add meridian..." [filteredKeywords]="filteredMeridians" (filter)="filterMeridian($event)"></av-keywords>
  </div>
  <div class="form-row">
    <input formControlName="name" type="text" placeholder="Point name. For example LV-3">
  </div>
  <div class="form-row">
    <input formControlName="label" type="text" placeholder="Secondary name(s)">
  </div>
  <div class="form-row">
    <textarea formControlName="description" rows="4" placeholder="Location of the point and notes"></textarea>
  </div>
  <div class="form-row">
    <av-keywords [keywords]="keywords" placeholder="Add condition..." [filteredKeywords]="filteredKeywords" (filter)="filterKeyword($event)"></av-keywords>
  </div>
  <div class="form-row__submit" [avLoading]="loading">
    <div class="av-link" (click)="cancel.next(_data)">Cancel</div>
    <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="onSubmit()">Submit</button>
  </div>
  <div class="point-edit__error">{{error}}</div>
</form>
