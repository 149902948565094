import { Injectable } from '@angular/core';
import { action, EntityStore, StoreConfig } from '@datorama/akita';
import { AccuPoint, AccuPointGroup, MeridianInfo } from 'src/app/models';
import { AccuPointsState } from './points-state.model';

const INITIAL_STATE = {
  active: null,
  filters: [],
  meridians: [],
} as AccuPointsState;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'PointsState', idKey: 'id' })
export class AccuPointsStore extends EntityStore<AccuPointsState> {
  constructor() {
    super(INITIAL_STATE);
  }

  @action('Set active point')
  setActive(stateItem: AccuPoint) {
    this.update({ active: stateItem });
  }

  @action('Set meridians')
  setMeridians(meridians: MeridianInfo[]) {
    this.update({ meridians });
  }

  @action('Set filters')
  setFilters(filters: AccuPointGroup[]) {
    this.update({ filters: [...filters] });
  }
}
