import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccuPoint, AccuPointGroup } from 'src/app/models';
import { AuthService, PointGroupsService } from 'src/app/services';
import { AccuPointsStoreService } from 'src/app/stores/points/accu-points.service';

@Component({
  selector: 'av-point-info',
  templateUrl: './point-info.component.html',
  styleUrls: ['./point-info.component.scss'],
})
export class PointInfoComponent implements OnInit {
  constructor(
    private auth: AuthService, 
    private pointGroupsService: PointGroupsService, 
    private accuPointsStoreService: AccuPointsStoreService
  ) {}

  _data: AccuPoint;
  @Input() set data(inp: AccuPoint) {
    this._data = inp;

    this.groups = null;
    if (inp) {
      if (inp.meridians) {
        this.meridians = inp.meridians.map((group) => group.name).join(', ');
      }
      if (inp.groups) {
        this.groups = inp.groups.map((group) => group.name).join(', ');
      }
    }
  }
  get data(): AccuPoint {
    return this._data;
  }

  @Input() editable = false;

  tokenInfo = this.auth.getTokenInfo();
  meridians: string;
  groups: string;

  @Output() finish = new EventEmitter<AccuPoint>();
  @Output() edit = new EventEmitter<AccuPoint>();
  @Output() delete = new EventEmitter<AccuPoint>();
  @Output() find = new EventEmitter<AccuPoint>();

  ngOnInit(): void {}

  clickGroup(group: AccuPointGroup) {
    this.accuPointsStoreService.addFilter(group);
  }
  favorCondition(group: AccuPointGroup) {
    this.pointGroupsService.togglePointVote(this._data.id, group.id).subscribe((point) => {
      this.accuPointsStoreService.setActive(point);
    });
  }
}
