import { Injectable } from '@angular/core';
import { action, Store, StoreConfig } from '@datorama/akita';
import { UserInfo } from 'src/app/models';
import { AuthService, UserService } from 'src/app/services';
import { SessionState } from './session.model';

const INITIAL_STATE = {
  user: null,
} as SessionState;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Session' })
export class SessionStore extends Store<SessionState> {
  constructor(private authService: AuthService, private userService: UserService) {
    super(INITIAL_STATE);
    if (this.authService.getToken()) {
      this.userService.get().subscribe((user) => {
        this.setUser(user);
      });
    }
  }

  @action('Set user')
  setUser(user: UserInfo) {
    // const FS = window['FS'];
    // if (FS && FS.identify) {
    //   FS.identify(user.id, {
    //     displayName: user.email,
    //     email: user.email,
    //   });
    // }

    this.update({ user });
  }

  @action('Reset')
  logout() {
    this.reset();
  }
}
