import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  ViewContainerRef,
  HostBinding,
  EmbeddedViewRef,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { SpinnerComponent } from '../components';

@Directive({
  selector: '[avLoading]',
})
export class LoadingDirective {
  @HostBinding('style.position') position = 'relative';

  @Input('avLoading')
  set loading(isLoading: boolean) {
    if (isLoading && !this.spinnerComponent) {
      const host = this.findHost(this.element.nativeElement);
      if (host) {
        this.spinnerComponent = this.viewContainerRef.createComponent(this.spinnerFactory);
        host.append(this.spinnerComponent.location.nativeElement);
        this.renderer.setStyle(this.element.nativeElement, 'pointer-events', 'none');
      }
    } else if (!isLoading && this.spinnerComponent) {
      this.spinnerComponent.destroy();
      this.spinnerComponent = null;
      this.renderer.removeStyle(this.element.nativeElement, 'pointer-events');
    }
  }

  spinnerFactory: ComponentFactory<SpinnerComponent>;
  templateView: EmbeddedViewRef<SpinnerComponent>;
  spinnerComponent: ComponentRef<SpinnerComponent>;

  constructor(
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
  ) {
    this.spinnerFactory = this.componentFactoryResolver.resolveComponentFactory(SpinnerComponent);
  }

  private findHost(el: any): any {
    // attach spinner to element or if its not DOM element attach to parent
    // for example if you add taLoading to ng-container
    if (el.append === undefined) {
      if (el.parentElement) {
        return this.findHost(el.parentElement);
      }
      return null;
    }
    return el;
  }
}
