<div class="point-info">
  <div class="point-info__row point-info__title">
    <label>{{data.name}}:</label> {{data.label}}
    <span *ngIf="meridians">&nbsp; [{{meridians}}]</span>
    <av-icon name="cancel" class="point-info__close" (click)="finish.next(data)"></av-icon>
  </div>
  <div class="point-info__row point-info__notes">
    <label>Location:</label>
    <span class="av-link" matTooltip="Click here to zoom on point" (click)="find.next(data)">{{data.description}}</span>
  </div>
  <div class="point-info__row point-info__groups">
    <label>Conditions:</label>     
    <span *ngFor="let group of _data.groups" class="point-info__group">
      <div class="av-link" matTooltip="Add to filter" (click)="clickGroup(group)">{{group.name}}</div>
      <div *ngIf="!!tokenInfo && group.weight > 0">{{group.weight}}</div>
      <av-icon *ngIf="!!tokenInfo" name="thumb-up" [class.point-info__group-myvote]="!!group.myVote" [matTooltip]="group.myVote ? 'Remove vote' : 'Upvote'" (click)="favorCondition(group)"></av-icon>
    </span>
  </div>

  <div *ngIf="editable" class="point-info__controls">
    <div class="av-link" (click)="edit.next(data)">Edit</div>
    <div class="av-link" (click)="delete.next(data)">Delete</div>
  </div>
</div>
