import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseServerResponse, PointHistory } from '../models';
import { ApiService } from './api.service';
import { PointsService } from './points.service';

const apiUrl = '/api/v1/point-history';

@Injectable({
  providedIn: 'root',
})
export class PointHistoryService {
  constructor(private api: ApiService, private pointService: PointsService) {}

  getAll(limit?: number, offset?: number): Observable<BaseServerResponse<PointHistory>> {
    const queryParams = {} as any;
    if (limit) {
      queryParams.limit = limit;
    }
    if (offset) {
      queryParams.offset = offset;
    }

    return this.api.get(apiUrl, {queryParams}).pipe(
      map((resp) => {
        const result = resp as BaseServerResponse<PointHistory>;
        result.list = result.list.map((item) => this.fromServerData(item));
        return result;
      }),
    );
  }

  filter(userId?: string, pointId?: string, limit?: number, offset?: number): Observable<BaseServerResponse<PointHistory>> {
    const queryParams = {} as any;
    if (userId) {
      queryParams.user_id = userId;
    }
    if (pointId) {
      queryParams.point_id = pointId;
    }
    if (limit) {
      queryParams.limit = limit;
    }
    if (offset) {
      queryParams.offset = offset;
    }
    return this.api.get(`${apiUrl}/filter`, { queryParams }).pipe(
      map((resp) => {
        const result = resp as BaseServerResponse<PointHistory>;
        result.list = result.list.map((item) => this.fromServerData(item));
        return result;
      }),
    );
  }

  restore(itemId: number, notes: string) {
    return this.api.post(`${apiUrl}/${itemId}`, {body: {notes}});
  }

  private fromServerData(item: any): PointHistory {
    const res = {
        id: item.id,
        pointId: item.point_id,
        userId: item.user_id,
        userEmail: item.email,
        timestamp: new Date(item.timestamp),
        action: item.action,
        point: item.json ? this.pointService.fromServerData(JSON.parse(item.json)) : null,
        notes: item.notes,
    } as unknown as PointHistory;

    return res;
  }
}
