import { Point2, Point3 } from 'src/app/models';
import * as THREE from 'three';

export interface SelectionEvent {
  point: Point3;
  mesh: THREE.Object3D;
}

export class MouseSelector {
  domElem: any;
  raycaster = new THREE.Raycaster();

  pickMesh(mousePos: Point2, camera: THREE.Camera, objects: THREE.Object3D[]): SelectionEvent {
    const mouse = this.posFromMouse(mousePos);

    // update the picking ray with the camera and mouse position
    this.raycaster.setFromCamera(mouse, camera);

    // calculate objects intersecting the picking ray
    const intersects = this.raycaster.intersectObjects(objects);

    for (const intersect of intersects) {
      return {
        point: {
          x: intersect.point.x,
          y: intersect.point.y,
          z: intersect.point.z,
        },
        mesh: intersect.object,
      };
    }

    return null;
  }

  private posFromMouse(mousePos: Point2): THREE.Vector2 {
    return new THREE.Vector2(
      (mousePos.x / this.domElem.clientWidth) * 2 - 1,
      -(mousePos.y / this.domElem.clientHeight) * 2 + 1,
    );
  }
}
