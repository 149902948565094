import { Component, OnInit } from "@angular/core";
import { combineLatest } from "rxjs";
import { AutoUnsubscribeComponent } from "src/app/components/auto-unsubscribe/auto-unsubscribe.component";
import { AccuPointGroup, MeridianInfo } from "src/app/models";
import { AccuPointsQuery } from "src/app/stores/points/accu-points.query";
import { AccuPointsStoreService } from "src/app/stores/points/accu-points.service";
import * as THREE from 'three';

interface MeridianInfoWrap extends MeridianInfo {
  active: boolean;
}

@Component({
  selector: 'av-meridians',
  templateUrl: './meridians.component.html',
  styleUrls: ['./meridians.component.scss'],
})
export class MeridiansComponent extends AutoUnsubscribeComponent implements OnInit {

  constructor(private pointsQuery: AccuPointsQuery, private pointsService: AccuPointsStoreService) {
    super();
  }
  
  showList = false;
  meridians: MeridianInfoWrap[] = [];
  filters: AccuPointGroup[];
  totalActive = 0;

  ngOnInit() {
    this.subscriber = combineLatest([this.pointsQuery.meridians$, this.pointsQuery.filters$]).subscribe(([meridians, filters]) => {
      this.filters = filters;

      const filterMap = {};
      filters.forEach(group => {
        filterMap[group.id] = true;
      });

      this.totalActive = 0;
      this.meridians = meridians.map(meridian => {
        const active = !!filterMap[meridian.id];
        if (active) {
          this.totalActive += 1;
        }
        return {
          ...meridian,
          active,
        };
      });

      if (this.totalActive === 0) {
        this.totalActive = this.meridians.length;
      }
    });
  }

  toggle() {
    this.showList = !this.showList;
  }

  onSelect(meridian: MeridianInfoWrap) {
    if (meridian.active) {
      const newFilters = this.filters.filter(group => group.id != meridian.id);
      this.pointsService.setFilters(newFilters);
    } else {
      const newFilters = [
        ...this.filters,
      ];
      newFilters.push({
        ...meridian
      });
      this.pointsService.setFilters(newFilters);
    }
  }
}
