import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccuPoint } from 'src/app/models';

@Component({
  selector: 'av-point-list',
  templateUrl: './point-list.component.html',
  styleUrls: ['./point-list.component.scss']
})
export class PointListComponent implements OnInit {

  constructor() {}

  _points: AccuPoint[];
  @Input() set points(inp: AccuPoint[]) {
    this._points = inp;
  }

  @Output() find = new EventEmitter<AccuPoint>();

  ngOnInit(): void {
  }
}
