<av-frame>
  <div class="accupoints">
    <div class="left-panel">
      <div class="left-panel__top">
        <av-main-search [canAdd]="canUserEdit && !editPoint && (!activePoint || !!activePoint.id)" (selectActive)="onSelectPoint($event)"></av-main-search>
      </div>

      <div class="left-panel__content">
        <div *ngIf="activePoint" class="left-panel__point-info">
          <av-point-info *ngIf="!editPoint" [data]="activePoint" [editable]="canUserEdit" (edit)="onEditPoint($event)" (delete)="onDeletePoint($event)" (find)="onShowPoint($event)" (finish)="onSelectPoint(null)"></av-point-info>
          <av-point-edit *ngIf="editPoint" [data]="activePoint" (afterSubmit)="submitPoint($event)" (cancel)="cancelPointEdit($event)"></av-point-edit>
        </div>

        <div *ngIf="!activePoint && haveFilters" class="left-panel__point-list">
          <av-point-list [points]="accuPoints" (find)="onSelectPoint($event); onShowPoint($event)"></av-point-list>
        </div>

        <div *ngIf="!activePoint && !haveFilters" class="left-panel__welcome">
          <h2>Welcome to AcuPedia! Place for all interested in acupuncture and acupressure points. </h2>
          <p *ngIf="!canUserEdit">
            We leave it to our users to add more information or correct any mistakes they find. 
            Want to contribute to the knowledge? 
            <a *ngIf="!tokenInfo" class="av-link" [routerLink]="'/auth'" [queryParams]="{'step': 'signup'}">Create an account.</a>
            <span *ngIf="tokenInfo">
              <a class="av-link" (click)="openSupportModal()">Contact us to request Editor access.</a>
            </span>
          </p>

          <p>Filter by meridians, conditions or search for a specific point above. You can also click on the point in the human body to view its details.</p>

          <div class="email-info">Questions? Contact us at <a href="mailto:support@accu-pedia.com" class="av-link">support@accu-pedia.com</a></div>
        </div>
      </div>
    </div>
    <av-bodymap [data]="bodyData" [showLineEdit]="tokenInfo?.role === 'ADMIN'" (mouseClick)="bodymapClick($event)"></av-bodymap>
  </div>
</av-frame>
