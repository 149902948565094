<h1 mat-dialog-title>Contact us</h1>
<form action="" [formGroup]="form" class="point-edit">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Subject</mat-label>
      <input matInput formControlName="subject" required/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Message</mat-label>
      <textarea rows="5" matInput formControlName="message" required></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="action-row">
    <button mat-button (click)="dialogRef.close()">Cancel</button>
    <button mat-button [disabled]="!form.valid" [avLoading]="loading" cdkFocusInitial (click)="send()">Send</button>
  </div>
</form>