import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { BodymapComponent, ConfirmationModal } from 'src/app/components';
import { AccuPoint, AccuPointGroup, BaseServerResponse, BodymapData, PointHistory } from 'src/app/models';
import { PointHistoryService, PointsService, UserService } from 'src/app/services';

interface PointHistoryWrap extends PointHistory {
  changes: string[];
}

@Component({
  selector: 'av-admin-point-history',
  templateUrl: './admin-point-history.component.html',
  styleUrls: ['./admin-point-history.component.scss']
})
export class AdminPointHistoryComponent implements OnInit {
    @ViewChild(BodymapComponent) bodyMap;

    constructor(private route: ActivatedRoute, public dialog: MatDialog, private pointHistoryService: PointHistoryService, private pointService: PointsService, private userService: UserService) {
        this.pointId = this.route.snapshot.paramMap.get('pointId');
        this.pointService.get(this.pointId).subscribe(point => {
            this.point = point;
        })
    }

  pageSize = 10;
  activePage = 0;

  pointId: string;
  point: AccuPoint;
  activeItem: PointHistoryWrap;

  history: BaseServerResponse<PointHistoryWrap>;

  bodyData: BodymapData = {
    points: [],
    lines: [],
  };

  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(pageIndex = 0) {
    this.activePage = pageIndex;

    let historyObs: Observable<BaseServerResponse<PointHistory>>;
    const offset = this.activePage * this.pageSize;

    this.pointHistoryService.filter(null, this.pointId, this.pageSize + 1, offset).subscribe(resp => {
      const list: PointHistoryWrap[] = [];
      resp.list.forEach((item, index) => {
        if (index < resp.list.length - 1) {
          list.push(this.processItem(item, resp.list[index + 1]));
        } else if (offset + index === resp.total - 1) {
          list.push(item as PointHistoryWrap);
        }
      });

      this.history = {total: resp.total, list} as BaseServerResponse<PointHistoryWrap>
    });
  }

  viewPoint(item: PointHistoryWrap) {
    this.activeItem = item;

    const point = item.point;
    this.bodyData = {
      points: [{
        point: point.location,
        color: 0x0,
        label: point.name,
        userData: point,
      }],
      lines: []
    };
  }

  onShowPoint(point: AccuPoint) {
    if (point.camera && this.bodyMap) {
      this.bodyMap.moveCamera(point.camera, point.location);
    }
  }

  restore(item: PointHistory) {
    const dialogRef = this.dialog.open(ConfirmationModal, {
      width: '400px',
      data: {
        title: 'Restore Point',
        text: `Are you sure you want to restore point ${item.point.name}`,
        buttonPrimary: 'Restore',
        buttonSecondary: 'Cancel',
        notesHint: 'Why are you restoring?'
      }
    });

    dialogRef.afterClosed().subscribe(notes => {
      if (notes) {
        this.pointHistoryService.restore(item.id, notes).subscribe(() => {
          this.loadPage(0);
        });
      }
    });
  }

  private processItem(item: PointHistory, prev: PointHistory): PointHistoryWrap {
    const res = {...item, changes: []} as PointHistoryWrap;

    if (item.point && item.action === 'UPDATE') {
      if (item.point.name !== prev.point.name) {
        res.changes.push('Name');
      }
      if (item.point.label !== prev.point.label) {
        res.changes.push('Label');
      }
      if (item.point.description !== prev.point.description) {
        res.changes.push('Description');
      }
      if (this.getGroupsString(item.point.meridians) !== this.getGroupsString(prev.point.meridians)) {
          res.changes.push('Meridians');
      }
      if (this.getGroupsString(item.point.groups) !== this.getGroupsString(prev.point.groups)) {
        res.changes.push('Conditions');
      }
      if (JSON.stringify(item.point.location) !== JSON.stringify(prev.point.location)) {
        res.changes.push('Location');
      }
    }
    return res;
  }

  private getGroupsString(groups: AccuPointGroup[]): string {
    return groups ? groups.map(group => group.id).join(',') : '';
  }
}
