import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, TokenInfo } from 'src/app/services';
import { validators } from 'src/app/utils/validators';

@Component({
  selector: 'av-pwd-reset',
  templateUrl: './pwd-reset.component.html',
  styleUrls: ['./pwd-reset.component.scss']
})
export class PwdResetComponent {
  constructor(private fb: FormBuilder, private auth: AuthService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      if (!params.token) {
        window.location.href = '/';
      }
      this.token = params.token;
      this.jwt = this.auth.parseToken(this.token);

      this.form = this.fb.group(
        {
          email: ['', [Validators.email, Validators.required, validators.matchValue(this.jwt.email)]],
          password: ['', Validators.compose([Validators.required, validators.passwordPattern()])],
          password2: ['', [Validators.required]],
        },
        {
          validator: validators.fieldsMatch('password', 'password2'),
        },
      );
    });
  }

  @Output() gotoLogin = new EventEmitter();

  token: string;
  jwt: TokenInfo;
  form: FormGroup;

  success = false;
  loading = false;
  error: string;

  get emailError(): string {
    return this.form.controls.email.errors && this.form.controls.email.errors.matchValue
      ? 'Email must match email from the token'
      : null;
  }
  get pwdError(): string {
    return this.form.controls.password.errors && this.form.controls.password.errors.pattern
      ? 'Password must be at least 6 characters long'
      : null;
  }
  get pwd2Error(): string {
    return this.form.controls.password2.errors && this.form.controls.password2.errors.mustMatch
      ? 'Both password fields must match'
      : null;
  }

  onSubmit() {
    this.error = null;
    this.loading = true;

    this.auth.pwdReset(this.token, this.form.value.email, this.form.value.password).subscribe(
      () => {
        this.loading = false;
        this.success = true;
      },
      (error) => {
        this.loading = false;
        this.error = error.message ? error.message : 'Expired or invalid email link. Please try to issue email again.';
      },
    );
  }
}
