import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BaseServerResponse, UserInfo } from 'src/app/models';
import { UserService } from 'src/app/services';
import { AdminUserModalComponent } from './components/admin-user-modal/admin-user-modal.component';

@Component({
  selector: 'av-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

  constructor(public dialog: MatDialog, private userService: UserService) {
    this.loadPage();
  }

  users: BaseServerResponse<UserInfo>;
  pageSize = 10;
  activePage = 0;
  
  ngOnInit(): void {
  }

  loadPage(pageIndex = 0) {
    this.activePage = pageIndex;
    this.userService.list(pageIndex * this.pageSize, this.pageSize).subscribe(data => {
      this.users = data;
    });
  }

  editUser(user: UserInfo) {
    const dialogRef = this.dialog.open(AdminUserModalComponent, {
      width: '400px',
      data: user,
    });

    dialogRef.afterClosed().subscribe((data: FormGroup) => {
      this.userService.patchUser(user.id, {
        state: data.value.state,
        role: data.value.role,
        notes: data.value.notes,
      }).subscribe(() => {
        this.loadPage(this.activePage);
      });
    });
  }
}
