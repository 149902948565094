import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccupointsPageComponent, AuthPageComponent, AdminHistoryComponent, AdminPointHistoryComponent, AdminUsersComponent, PwdResetComponent } from './features';
import { AuthRouteGuard } from './services/auth-route-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AccupointsPageComponent,
  },
  {
    path: 'password-reset',
    component: PwdResetComponent,
  },
  {
    path: 'admin-users',
    component: AdminUsersComponent,
    data: {admin: true},
    canActivate: [AuthRouteGuard],
  },
  {
    path: 'admin-history',
    component: AdminHistoryComponent,
    data: {admin: true},
    canActivate: [AuthRouteGuard],
  },
  {
    path: 'admin-history/user/:userId',
    component: AdminHistoryComponent,
    data: {admin: true},
    canActivate: [AuthRouteGuard],
  },
  {
    path: 'admin-history/point/:pointId',
    component: AdminPointHistoryComponent,
    data: {admin: true},
    canActivate: [AuthRouteGuard],
  },
  {
    path: 'auth',
    component: AuthPageComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },   
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
  providers: [AuthRouteGuard],
})
export class AppRoutingModule {}
