import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import {MatDialogRef} from '@angular/material/dialog';
import { ApiService } from "src/app/services/api.service";

@Component({
    selector: 'av-support-modal',
    templateUrl: './support-modal.component.html',
    styleUrls: ['./support-modal.component.scss'],
})
export class SupportModal {
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<SupportModal>, private apiService: ApiService) {}

  form = this.fb.group({
    subject: ['', [Validators.required]],
    message: ['', [Validators.required]],
  });

  loading = false;

  send() {
    this.loading = true;

    const body = {
      subject: this.form.value.subject,
      message: this.form.value.message,
    };

    this.apiService.post('/api/v1/email/support', {body}).subscribe(resp => {
      this.dialogRef.close();
    });
  }
}