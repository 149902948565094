import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

export interface KeywordGroup {
  id: number;
  name: string;
}

@Component({
  selector: 'av-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'],
})
export class KeywordsComponent {
  @ViewChild('keywordInput') keywordInput: ElementRef<HTMLInputElement>;

  constructor() {
    this.keywordCtrl.valueChanges.pipe(debounceTime(300)).subscribe((term) => {
      this.filter.next(term);
    });
  }

  separatorKeysCodes: number[] = [ENTER, COMMA];
  keywordCtrl = new FormControl();

  @Input() label: string;
  @Input() keywords: KeywordGroup[] = [];
  @Input() canAdd = true;
  @Input() canDelete = true;
  @Input() filteredKeywords: KeywordGroup[];
  @Input() placeholder = 'New keyword...';

  @Output() filter = new EventEmitter<string>();
  @Output() change = new EventEmitter<KeywordGroup[]>();

  add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const values = value.trim().split(','); // user can copy/paste comma separated values

      values.forEach((newValue) => {
        const val = newValue.trim();
        if (val) {
          this.keywords.push({
            id: null,
            name: val,
          });
        }
      });

      this.change.next(this.keywords);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.keywordCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.keywords.push(event.option.value);
    this.keywordInput.nativeElement.value = '';
    this.keywordCtrl.setValue(null);
    this.change.next(this.keywords);
  }

  remove(index: number) {
    this.keywords.splice(index, 1);
    this.change.next(this.keywords);
  }
}
