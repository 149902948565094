import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccuPoint, AccuPointGroup, AccuPointGroupType, MeridianInfo, Point3 } from '../models';
import { ApiService } from './api.service';

const apiUrl = '/api/v1/point-groups';

@Injectable({
  providedIn: 'root',
})
export class PointGroupsService {
  constructor(private api: ApiService) {}

  getAll(
    term?: string,
    type?: AccuPointGroupType,
    excludeIds?: number[],
    limit?: number,
  ): Observable<AccuPointGroup[]> {
    const queryParams = {} as any;
    if (term) {
      queryParams.term = term;
    }
    if (type) {
      queryParams.type = type;
    }
    if (excludeIds && excludeIds.length > 0) {
      queryParams.excludeIds = excludeIds.join(',');
    }
    if (limit) {
      queryParams.limit = limit;
    }
    return this.api.get(apiUrl, { queryParams });
  }

  getFromIds(ids: number[]): Observable<AccuPointGroup[]> {
    const idList = ids.join(',');
    return this.api.get(`${apiUrl}/from-ids`, { queryParams: {ids: idList} });
  }

  getMeridians(): Observable<MeridianInfo[]> {
    return this.api.get(`${apiUrl}/meridians`).pipe(
      map((meridians: MeridianInfo[]) => {
        meridians.forEach(meridian => {
          meridian.points.forEach(point => {
            point.location = JSON.parse(point.location as unknown as string) as Point3;
          });
        });
        return meridians;
      })
    );
  }

  togglePointVote(pointUuid: string, groupId: number): Observable<AccuPoint> {
    return this.api.put(`${apiUrl}/${pointUuid}/${groupId}`);
  }
}
