import * as THREE from 'three';

interface AnimationItem {
  mixer: THREE.AnimationMixer;
  action: THREE.AnimationAction;
  object: THREE.Object3D;
  lookAt: THREE.Vector3;
  clock: THREE.Clock;
}

export class ObjectAnimator {
  private _animations: AnimationItem[] = [];

  setup(obj: THREE.Object3D, translations: THREE.Vector3[], lookAt: THREE.Vector3, duration: number) {
    const mixer = new THREE.AnimationMixer(obj);

    const tracks = [];
    if (translations) {
      const pos = [];
      const steps = [0];
      translations.forEach((v3, index) => {
        pos.push(v3.x, v3.y, v3.z);
        if (index > 0) {
          steps.push((duration / translations.length) * (index + 1));
        }
      });
      const track = new THREE.VectorKeyframeTrack('.position', steps, pos, THREE.InterpolateSmooth);
      tracks.push(track);
    }

    const animationClip = new THREE.AnimationClip('test', duration, tracks);
    const animationAction = mixer.clipAction(animationClip);
    animationAction.setLoop(THREE.LoopOnce, 1);
    animationAction.clampWhenFinished = true;
    animationAction.play();

    this._animations.push({
      mixer,
      action: animationAction,
      object: obj,
      lookAt,
      clock: new THREE.Clock(),
    });
  }

  render() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this._animations.length; i++) {
      const item = this._animations[i];

      if (!item.action.paused) {
        const delta = item.clock.getDelta();
        item.mixer.update(delta);
        if (item.lookAt) {
          item.object.lookAt(item.lookAt);
        }
      } else {
        this._animations.splice(i, 1);
        i--;
      }
    }
  }
}
