import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccuPoint, AccuPointGroup } from 'src/app/models';
import { PointGroupsService, PointsService } from 'src/app/services';
import { AccuPointsStore } from './accu-points.store';

@Injectable({ providedIn: 'root' })
export class AccuPointsStoreService {
  constructor(
    private router: Router,
    private store: AccuPointsStore,
    private pointsService: PointsService,
    private pointGroupsService: PointGroupsService,
  ) {
    this.pointGroupsService.getMeridians().subscribe((meridians) => {
      this.store.setMeridians(meridians);
    });
    this.reload();
  }

  reload() {
    const groupIds = this.store.getValue().filters.map((group) => group.id);
    this.pointsService.getAll(null, groupIds).subscribe((points) => {
      points.map(point => {
        const parts = point.name.split('-');
        try {
          point.meridianCode = parts[0].toLowerCase();
          point.index = Number.parseInt(parts[1]);
        } catch {}
      });
      this.store.set(points);
    });
  }

  setActive(point: AccuPoint) {
    this.store.setActive(point);
  }

  setFilters(filters: AccuPointGroup[]) {
    this.store.setFilters(filters);
    this.reload();
    this.router.navigate([], {queryParams: {groups: filters.map(filter => filter.id).join(',')}});
  }
  addFilter(filter: AccuPointGroup) {
    const filters = this.store.getValue().filters;
    if (!filters.find(filt => filt.type === filter.type)) {
      this.setFilters([...this.store.getValue().filters, filter]);
    }
  }

  upsert(point: AccuPoint) {
    this.store.upsert(point.id, point);
  }

  delete(id: string) {
    this.store.remove(id);
  }

  private sortList(item1: AccuPoint, item2: AccuPoint) {
    const res1 = item1.meridianCode || '';
    const res2 = item2.meridianCode || '';
    if (res1 < res2) {
      return -1;
    }
    if (res1 > res2) {
      return 1;
    }
    return item1.index - item2.index;
  }
}
