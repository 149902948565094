import { Point3 } from './base';

export type AccuPointGroupType = 'CONDITION' | 'GROUP';
export interface AccuPointGroup {
  id: number;
  name: string;
  type: AccuPointGroupType;
  weight?: number;
  myVote?: string;
}

export interface  MeridianInfo extends AccuPointGroup {
  points: AccuPoint[];
}

export interface AccuPoint {
  id: string;
  name: string;
  label: string;
  description: string;
  location?: Point3; // 3D location on body
  camera?: Point3; // camera position for zooming on location
  meridians?: AccuPointGroup[];
  groups?: AccuPointGroup[];

  meridianCode?: string; // parsed from name
  index?: number; // parsed from name
}

export class AccuData {
  points: AccuPoint[] = [];
}
