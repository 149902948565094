<av-frame>
    <form *ngIf="!success" class="av-pwd-reset" action="" [formGroup]="form">
        <div class="form-row">
            <input formControlName="email" type="email" placeholder="Enter your email" autofocus>
        </div>
        <div class="form-row">
            <input formControlName="password" type="password" placeholder="Enter your password">
        </div>
        <div class="form-row">
            <input formControlName="password2" type="password" placeholder="Verify your password">
        </div>
        <div class="form-error">
            {{error || emailError || pwdError || pwd2Error}}
        </div>
        <div class="form-row__submit" [avLoading]="loading">
            <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="onSubmit()">Submit</button>
        </div>
    </form>

    <div *ngIf="success" class="av-pwd-reset">
        <h2>Your password was succesfully updated</h2>
        <div>
            <a class="av-link" [routerLink]="'/auth'">Back to Login</a>
        </div>
    </div>
</av-frame>