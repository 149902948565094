import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserInfo, UserState, UserRole, USER_ROLES, USER_STATES } from 'src/app/models';

@Component({
  selector: 'av-admin-user-modal',
  templateUrl: './admin-user-modal.component.html',
  styleUrls: ['./admin-user-modal.component.scss']
})
export class AdminUserModalComponent {

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AdminUserModalComponent>, @Inject(MAT_DIALOG_DATA) public user: UserInfo) {}

  states: UserState[] = USER_STATES as unknown as UserState[];
  roles: UserRole[] = USER_ROLES as unknown as UserRole[];
  
  form = this.fb.group({
    state: [this.user.state, [Validators.required]],
    role: [this.user.role],
    notes: [this.user.notes, [Validators.required]],
  });

}
