import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let headers = req.headers;

    // const defaultHeaders = { Accept: 'application/json, text/plain' };
    // if (!req.headers.get('Content-Type') && !req.headers.get('Content-Type-Undefined')) {
    //   defaultHeaders['Content-Type'] = 'application/json';
    // }

    const token = this.authService.getToken();
    if (token) {
      // pass token when there is no Authorization in header
      if (token && !headers.get('Authorization')) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }
    }

    const authReq = req.clone({ headers });

    return next.handle(authReq);
  }
}
