<av-frame>
    <div class="history-page">
        <div class="history-page__left">
            <h2>
                <ng-container *ngIf="!userId">Latest Changes</ng-container>
                <ng-container *ngIf="userId">Changes made by {{user?.email}}</ng-container>
            </h2>
                
            <div *ngIf="history">
                <table>
                    <tr>
                        <th>AcuPoint</th>
                        <th>Timestamp</th>
                        <th *ngIf="!userId">User</th>
                        <th>Action</th>
                    </tr>
                    <tr *ngFor="let item of history.list" class="history-item" [class.history-item--active]="item === activeItem" (click)="viewPoint(item)">
                        <td>
                            <a [routerLink]="'/admin-history/point/'+item.pointId" matTooltip="Filter changes just for that point">{{item.point.name}}</a>
                        </td>
                        <td>{{item.timestamp | date: 'medium'}}</td>
                        <td *ngIf="!userId">
                            <a [routerLink]="'/admin-history/user/'+item.userId" matTooltip="Filter changes by that user">{{item.userEmail}}</a>
                        </td>
                        <td class="action--{{item.action}}">{{item.action}}</td>
                    </tr>
                </table>

                <mat-paginator [length]="history.total" [hidePageSize]="true" [pageSize]="pageSize" [pageIndex]="activePage" (page)="loadPage($event.pageIndex)"></mat-paginator>
            </div>

            <div *ngIf="activeItem" class="history-page__info">
                <av-point-info [data]="activeItem.point" (find)="onShowPoint($event)"></av-point-info>
                <div *ngIf="activeItem.notes">
                    <label>Notes:</label> {{activeItem.notes}}
                </div>
            </div>    
        </div>
        <av-bodymap [data]="bodyData"></av-bodymap>
    </div>
</av-frame>
