import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services';
import { validators } from 'src/app/utils/validators';

@Component({
  selector: 'av-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {
  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router) {}

  @Output() postSignup = new EventEmitter();
  @Output() gotoLogin = new EventEmitter();

  form = this.fb.group(
    {
      email: ['', [Validators.email, Validators.required]],
      nickname: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', Validators.compose([Validators.required, validators.passwordPattern()])],
      password2: ['', [Validators.required]],
    },
    {
      validator: validators.fieldsMatch('password', 'password2'),
    },
  );
  loading = false;
  error: string;

  get pwdError(): string {
    return this.form.controls.password.errors && this.form.controls.password.errors.pattern
      ? 'Password must be at least 6 characters long'
      : null;
  }
  get pwd2Error(): string {
    return this.form.controls.password2.errors && this.form.controls.password2.errors.mustMatch
      ? 'Both password fields must match'
      : null;
  }

  onSubmit() {
    this.error = null;
    this.loading = true;

    this.auth.signup(this.form.value.email, this.form.value.password, this.form.value.nickname).subscribe(
      () => {
        this.loading = false;
        this.postSignup.next();
      },
      (error) => {
        this.loading = false;
        this.error = error.code === 'ER_DUP_ENTRY' ? 'User with this email or nickname already exists' : error.message;
      },
    );
  }
}
