<div class="av-keywords">
  <mat-chip-list #keywordsList aria-label="Keywords">
    <span *ngIf="label" class="av-keywords__label">{{label}}</span>
    <input
      *ngIf="canAdd"
      [placeholder]="placeholder"
      #keywordInput
      [formControl]="keywordCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="keywordsList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
    <mat-chip *ngFor="let keyword of keywords; let indx = index">
      {{keyword.name}}
      <av-icon name="cancel" *ngIf="canDelete" (click)="remove(indx)"></av-icon>
    </mat-chip>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let keyword of filteredKeywords" [value]="keyword">
      {{keyword.name}}
    </mat-option>
  </mat-autocomplete>
</div>
