<div class="frame-header">
  <div class="frame-header__logo" routerLink="/"></div>
  <div class="frame-header__fill">
    <div *ngIf="tokenInfo?.role === 'ADMIN'" class="frame-header__admin-links">
      <a [routerLink]="'/admin-history'" [class.active]="currentRoute === '/admin-history'">History</a>
      <div class="frame-header__divider"></div>
      <a [routerLink]="'/admin-users'" [class.active]="currentRoute === '/admin-users'">Users</a>  
    </div>
  </div>

  <div *ngIf="!tokenInfo && currentRoute !== '/auth'" class="av-link" [routerLink]="'/auth'">Sign in</div>

  <div *ngIf="tokenInfo" class="frame-header__welcome">
    {{tokenInfo.email}}
  </div>
  <div *ngIf="tokenInfo" class="av-link" (click)="logout()">Logout</div>
</div>
<div class="frame-content" [class.stretch]="stretch">
  <ng-content></ng-content>
</div>
