import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'av-page-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthPageComponent {
  constructor(private router: Router, private route: ActivatedRoute, private auth: AuthService) {
    this.route.queryParams.subscribe((params) => {
      if (params.step === 'signup') {
        this.activeTab = 1;
      }
    });
    if (this.auth.getToken()) {
      this.router.navigate(['/']);
    }
  }

  activeTab = 0;

  postSignup() {
    this.router.navigate(['/']);
  }
  postLogin() {
    this.router.navigate(['/']);
  }
  setActiveTab(tab: number) {
    this.activeTab = tab;
  }
}
