import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseServerResponse, UserInfo } from '../models';
import { ApiService } from './api.service';

const apiUrl = '/api/v1/users';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private api: ApiService) {}

  get(): Observable<UserInfo> {
    return this.api.get('/api/v1/profile');
  }

  list(offset = 0, limit = 10): Observable<BaseServerResponse<UserInfo>> {
    const queryParams = {} as any;
    if (offset) {
      queryParams.offset = offset;
    }
    if (limit) {
      queryParams.limit = limit;
    }
    return this.api.get(`${apiUrl}`, { queryParams }).pipe(map((data: any) => {
      data.list = data.list.map(user => this.fromServerData(user));
      return data as BaseServerResponse<UserInfo>;
    }));
  }

  getUser(id: string): Observable<UserInfo> {
    return this.api.get(`${apiUrl}/${id}`).pipe(map(user => this.fromServerData(user)));
  }

  patchUser(id: string, data: Partial<UserInfo>): Observable<UserInfo> {
    return this.api.patch(`${apiUrl}/${id}`, {body: data}).pipe(map(user => this.fromServerData(user)));
  }

  private fromServerData(item: any): UserInfo {
    const res = {
      ...item,
      timestamp: new Date(item.timestamp),
    } as unknown as UserInfo;

    return res;
  }
}
