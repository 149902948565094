import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { BodymapComponent } from 'src/app/components';
import { AccuPoint, BaseServerResponse, BodymapData, UserInfo, PointHistory } from 'src/app/models';
import { PointHistoryService, PointsService, UserService } from 'src/app/services';

@Component({
  selector: 'av-admin-history',
  templateUrl: './admin-history.component.html',
  styleUrls: ['./admin-history.component.scss']
})
export class AdminHistoryComponent implements OnInit {
  @ViewChild(BodymapComponent) bodyMap;

  constructor(private route: ActivatedRoute, private pointHistoryService: PointHistoryService, private pointService: PointsService, private userService: UserService) {
    this.userId = this.route.snapshot.paramMap.get('userId');
    if (this.userId) {
      this.userService.getUser(this.userId).subscribe(user => {
        this.user = user;
      })
    }
  }

  pageSize = 10;
  activePage = 0;

  activeItem: PointHistory;

  userId: string;
  user: UserInfo;

  history: BaseServerResponse<PointHistory>;

  bodyData: BodymapData = {
    points: [],
    lines: [],
  };


  ngOnInit(): void {
    this.loadPage();
  }

  loadPage(pageIndex = 0) {
    this.activePage = pageIndex;

    let historyObs: Observable<BaseServerResponse<PointHistory>>;
    const offset = this.activePage * this.pageSize;

    if (this.userId) {
      historyObs = this.pointHistoryService.filter(this.userId, null, this.pageSize, offset);
    } else {
      historyObs = this.pointHistoryService.getAll(this.pageSize, offset);
    }

    historyObs.subscribe(resp => {
      this.history = resp;
    });
  }

  viewPoint(item: PointHistory) {
    this.activeItem = item;

    const point = item.point;
    this.bodyData = {
      points: [{
        point: point.location,
        color: 0x0,
        label: point.name,
        userData: point,
      }],
      lines: []
    };
  }
  onShowPoint(point: AccuPoint) {
    if (point.camera && this.bodyMap) {
      this.bodyMap.moveCamera(point.camera, point.location);
    }
  }
}
