import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'av-chevron-toggle',
  templateUrl: './chevron-toggle.component.html',
  styleUrls: ['./chevron-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChevronToggleComponent {
  @Input() expanded = false;
}
