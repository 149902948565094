import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { UserRole } from '../models';

export interface TokenInfo {
  id: string;
  email: string;
  role: UserRole;
}

const apiUrl = '/api/v1';
const TOKEN_NAME = 'av-token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private api: ApiService, private router: Router) {}

  signup(email: string, password: string, nickname: string): Observable<void> {
    return this.api.post(`${apiUrl}/signup`, { body: { email, password, nickname } }).pipe(
      map((resp) => {
        this.saveToken(resp['token']);
      }),
    );
  }

  login(email: string, password: string): Observable<void> {
    return this.api.post(`${apiUrl}/login`, { body: { email, password } }).pipe(
      map((resp) => {
        this.saveToken(resp['token']);
      }),
    );
  }

  logout() {
    window.localStorage.removeItem(TOKEN_NAME);
    window.location.reload();
  }

  pwdResetRequest(email: string): Observable<void> {
    return this.api.post(`${apiUrl}/profile/pwd-reset-req`, { body: {email} });
  }

  pwdReset(token: string, email: string, password: string): Observable<void> {
    return this.api.post(`${apiUrl}/profile/pwd-reset`, { body: { email, password }, httpConfig: {headers: {'Authorization': `Bearer ${token}`}} });
  }

  getToken(): string {
    return window.localStorage.getItem(TOKEN_NAME);
  }

  getTokenInfo(): TokenInfo {
    return this.parseToken(this.getToken());
  }

  parseToken(token: string): TokenInfo {
    const helper = new JwtHelperService();
    const userInfo = token ? helper.decodeToken(token) : null;
    return userInfo ? userInfo.user : null;
  }

  private saveToken(token: string) {
    window.localStorage.setItem(TOKEN_NAME, token);
  }
}
