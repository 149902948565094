import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  template: ''
})
export class AutoUnsubscribeComponent implements OnDestroy {
  private _subscriptions: Subscription[] = [];
  protected set subscriber(inp: Subscription) {
    this._subscriptions.push(inp);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
