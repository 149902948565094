import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { combineLatest } from 'rxjs';
import { AccuPoint, AccuPointGroup } from 'src/app/models';
import { PointGroupsService, PointsService } from 'src/app/services';
import { AccuPointsQuery } from 'src/app/stores/points/accu-points.query';
import { AccuPointsStoreService } from 'src/app/stores/points/accu-points.service';
import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';
import { KeywordGroup } from '../keywords/keywords.component';
import { SearchItem } from '../search/search.component';

@Component({
  selector: 'av-main-search',
  templateUrl: './main-search.component.html',
  styleUrls: ['./main-search.component.scss'],
})
export class MainSearchComponent extends AutoUnsubscribeComponent {
  @ViewChild('focusEl') focusEl: ElementRef;

  constructor(
    private pointsQuery: AccuPointsQuery,
    private accuPointsStoreService: AccuPointsStoreService,
    private pointsService: PointsService,
    private pointGroupsService: PointGroupsService,
  ) {
    super();

    this.pointsQuery.points$.subscribe((list) => {
      this.buildSearchList(list);
    });
    this.pointsQuery.filters$.subscribe((filters) => {
      this.filters = filters.map(group => {
        return {
          id: group.id,
          name: group.name,
        };
      });
    });
  }

  searchList: SearchItem[] = [];
  filters: KeywordGroup[] = [];

  @Input() canAdd = true;
  @Output() selectActive = new EventEmitter<AccuPoint>();

  search(inp: string) {
    const term = inp ? inp.toLowerCase().trim() : '';
    const filterIds = this.filters.map((group) => group.id);
    if (term) {
      combineLatest([
        this.pointsService.getAll(term, filterIds, 10),
        this.pointGroupsService.getAll(term, null, filterIds, 10),
      ]).subscribe(([points, groups]) => {
        this.buildSearchList(points, groups);
      });
    } else {
      this.pointGroupsService.getAll().subscribe((groups) => {
        this.buildSearchList([], groups);
      });
    }
  }

  selectSearchItem(item: SearchItem) {
    if (!item.type) {
      this.focusEl.nativeElement.focus();
      this.selectActive.next(item.userData);
    } else {
      const group: AccuPointGroup = item.userData;
      this.filters.push({
        id: group.id,
        name: group.name,
      });
      this.filtersChanged();
    }
  }

  filtersChanged() {
    this.accuPointsStoreService.setFilters(this.filters as AccuPointGroup[]);
  }

  openCreate() {
    this.selectActive.next({ groups: [] } as AccuPoint);
  }

  private buildSearchList(list: AccuPoint[], groups?: AccuPointGroup[]) {
    this.searchList = [];

    if (groups) {
      groups.forEach((group) => {
        this.searchList.push({
          id: group.name,
          label: group.name,
          type: group.type,
          userData: group,
        });
      });
    }

    if (list) {
      list.forEach((point) => {
        this.searchList.push({
          id: point.name,
          label: point.name,
          userData: point,
        });
      });
    }
  }
}
