<form class="av-signup" action="" [formGroup]="form">
  <div class="form-row">
    <input formControlName="email" type="email" placeholder="Enter your email" autofocus>
  </div>
  <div class="form-row">
    <input formControlName="nickname" type="text" placeholder="Nickname (others will see that)">
  </div>
  <div class="form-row">
    <input formControlName="password" type="password" placeholder="Enter your password">
  </div>
  <div class="form-row">
    <input formControlName="password2" type="password" placeholder="Verify your password">
  </div>
  <div class="form-error">
    {{error || pwdError || pwd2Error}}
  </div>
  <div class="form-row__submit" [avLoading]="loading">
    <div class="av-signup__register">Already have an account? <span class="av-link" (click)="gotoLogin.next()">Sign in</span></div>
    <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="onSubmit()">Submit</button>
  </div>
</form>
