import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccuPoint, Point3 } from '../models';
import { ApiService } from './api.service';

const apiUrl = '/api/v1/accupoints';

@Injectable({
  providedIn: 'root',
})
export class PointsService {
  constructor(private api: ApiService) {}

  getAll(term?: string, groupIds?: number[], limit?: number): Observable<AccuPoint[]> {
    const queryParams = {} as any;
    if (term) {
      queryParams.term = term;
    }
    if (groupIds && groupIds.length > 0) {
      queryParams.groupIds = groupIds.join(',');
    }
    if (limit) {
      queryParams.limit = limit;
    }
    return this.api.get(apiUrl, { queryParams }).pipe(
      map((resp) => {
        return (resp as any[]).map((item) => this.fromServerData(item));
      }),
    );
  }

  get(id: string): Observable<AccuPoint> {
    return this.api.get(`${apiUrl}/${id}`).pipe(
      map((resp) => {
        return this.fromServerData(resp);
      }),
    );
  }

  create(inp: AccuPoint): Observable<AccuPoint> {
    return this.api.post(apiUrl, { body: this.toServerData(inp) }).pipe(
      map((resp) => {
        return this.fromServerData(resp);
      }),
    );
  }

  update(inp: AccuPoint): Observable<AccuPoint> {
    return this.api.patch(`${apiUrl}/${inp.id}`, { body: this.toServerData(inp) }).pipe(
      map((resp) => {
        return this.fromServerData(resp);
      }),
    );
  }

  delete(id: string, notes?: string): Observable<void> {
    return this.api.delete(`${apiUrl}/${id}`, {body: {notes}}).pipe(
      map(() => {
        return;
      }),
    );
  }

  fromServerData(inp: any): AccuPoint {
    const res = inp as AccuPoint;
    if (inp.location) {
      res.location = JSON.parse(inp.location) as Point3;
    }
    if (inp.camera) {
      res.camera = JSON.parse(inp.camera) as Point3;
    }
    if (res.groups) {
      const meridians = [];
      const conditions = [];
      res.groups.forEach((group) => {
        if (group.type === 'GROUP') {
          meridians.push(group);
        } else if (group.type === 'CONDITION') {
          conditions.push(group);
        }
      });
      res.meridians = meridians;
      res.groups = conditions;
    }

    return res;
  }
  private toServerData(inp: AccuPoint): any {
    const res = { ...inp } as any;
    if (inp.location) {
      res.location = JSON.stringify(inp.location);
    }
    if (inp.camera) {
      res.camera = JSON.stringify(inp.camera);
    }

    res.groups = res.groups.concat(res.meridians);
    delete res.meridians;
    return res;
  }
}
