<div class="bodymap">
  <div class="bodymap__canvas" #main (pointerdown)="onMouseDown($event)" (pointerup)="onMouseUp($event)"></div>
  <button mat-raised-button class="reset" (click)="onResetCamera()">Recenter</button>
  <div class="view-type">
    <mat-button-toggle-group [value]="viewType" (change)="onChangeView($event)">
      <mat-button-toggle value="skin">Skin</mat-button-toggle>
      <mat-button-toggle value="muscles">Muscles</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="bodymap__stats">
    <av-meridians></av-meridians>
    <div class="total">Total {{_data.points.length}} points</div>
  </div>
  <div *ngIf="showLineEdit" class="bodymap__edit">
    <av-line-editor></av-line-editor>
  </div>
</div>
