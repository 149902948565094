<div class="main-search">
  <av-search [list]="searchList" placeholder="Search example: liver meridian or migrane or BL-18" (keystroke)="search($event)" (select)="selectSearchItem($event)"></av-search>
  <div *ngIf="canAdd" class="av-link left-panel__create" matTooltip="Add new point" (click)="openCreate()">
    <av-icon name="add"></av-icon>
  </div>
</div>
<div *ngIf="filters.length > 0" class="main-search__filters">
  <av-keywords label="Show points for:" [keywords]="filters" [canAdd]="false" (change)="filtersChanged()"></av-keywords>
</div>
<div #focusEl tabindex="0"></div>
