<form class="av-forgot-pwd" action="" [formGroup]="form">
    <div class="form-row">
        <input formControlName="email" type="text" placeholder="Enter your email" autofocus>
    </div>
    <div class="form-error">
        {{error}}
        <span *ngIf="successMsg" class="av-forgot-pwd__success">{{successMsg}}</span>
    </div>
    <div class="form-row__submit" [avLoading]="loading">
        <div class="av-link" (click)="goBack.next()">Back to Login</div>
        <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="onSubmit()">Submit</button>
    </div>
  </form>
  