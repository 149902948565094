import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ɵConsole } from '@angular/core';
import { fromEvent, Observable, of } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';

export interface SearchItem {
  id: number | string;
  label: string;
  type?: string;
  userData?: any;
}

@Component({
  selector: 'av-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements AfterViewInit {
  @ViewChild('inputEl') inputEl: ElementRef;

  constructor() {}

  @Input() list: SearchItem[] = [];
  @Input() placeholder: string;

  @Output() keystroke = new EventEmitter<string>();
  @Output() select = new EventEmitter<SearchItem>();

  ngAfterViewInit() {
    let inpString = '';

    fromEvent(this.inputEl.nativeElement, 'keydown')
      .pipe(
        debounceTime(300),
        switchMap(
          (): Observable<string> => {
            return of(this.inputEl.nativeElement.value);
          },
        ),
        filter((inp) => inp !== inpString),
      )
      .subscribe((inp) => {
        inpString = inp;
        this.keystroke.emit(inp);
      });
  }

  displayFn(item: SearchItem): string {
    return item ? item.label : '';
  }

  optionSelect(event) {
    this.inputEl.nativeElement.value = '';
    this.select.next(event.option.value);
    this.keystroke.emit('');
  }
}
