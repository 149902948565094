import { Directive, Input, HostBinding, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[avDisabled]',
})
export class DisabledDirective {
  @HostBinding('style.position') position = 'relative';

  @Input('avDisabled')
  set loading(isDisabled: boolean) {
    if (isDisabled && !this.overlay) {
      this.host = this.findHost(this.element.nativeElement);
      if (this.host) {
        this.overlay = this.renderer.createElement('div');
        this.renderer.setAttribute(
          this.overlay,
          'style',
          'position:absolute; left:0; top:0; width:100%; height:100%; background-color:rgba(255,255,255,0.7)',
        );
        this.host.append(this.overlay);
        this.renderer.setStyle(this.element.nativeElement, 'pointer-events', 'none');
      }
    } else if (!isDisabled && this.overlay) {
      this.renderer.removeChild(this.host, this.overlay);
      // this.overlay.nativeElement.destroy();
      this.overlay = null;
      this.renderer.removeStyle(this.element.nativeElement, 'pointer-events');
    }
  }
  host: any;
  overlay: ElementRef;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  private findHost(el: any): any {
    // attach spinner to element or if its not DOM element attach to parent
    // for example if you add taLoading to ng-container
    if (el.append === undefined) {
      if (el.parentElement) {
        return this.findHost(el.parentElement);
      }
      return null;
    }
    return el;
  }
}
