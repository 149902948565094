<h1 mat-dialog-title>{{user.email}}</h1>
<form action="" [formGroup]="form" class="point-edit">
  <div mat-dialog-content>
    <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Role</mat-label>
      <mat-select formControlName="role">
        <mat-option [value]="null">None</mat-option>
        <mat-option *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Notes</mat-label>
      <textarea rows="3" matInput formControlName="notes" required></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="action-row">
    <button mat-button (click)="dialogRef.close()">Cancel</button>
    <button mat-button [disabled]="!form.valid" [mat-dialog-close]="form" cdkFocusInitial>Update</button>
  </div>
</form>