export const USER_ROLES = ['EDITOR', 'ADMIN'] as const;
export type UserRole = typeof USER_ROLES[number];

export const USER_STATES = ['ACTIVE', 'DISABLED'] as const;
export type UserState = typeof USER_STATES[number];

export interface UserInfo {
  id: string;
  timestamp: Date | string;
  email: string;
  role: UserRole;
  state: UserState;
  notes?: string;
}
