import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  IconComponent,
  ChevronToggleComponent,
  FrameComponent,
  BodymapComponent,
  LineEditorComponent,
  MeridiansComponent,
  SearchComponent,
  PointInfoComponent,
  PointEditComponent,
  KeywordsComponent,
  MainSearchComponent,
  ConfirmationModal,
  AutoUnsubscribeComponent,
  SupportModal,
} from './components';
import {
  AccupointsPageComponent,
  AuthPageComponent,
  AdminHistoryComponent,
  AdminPointHistoryComponent,
  AdminUsersComponent,
  PwdResetComponent,
  LoginComponent,
  SignupComponent,
  ForgotPwdComponent,
  PointListComponent,
} from './features';
import { DisabledDirective, LoadingDirective } from './directives';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccuPointsStoreService } from './stores/points/accu-points.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SessionStore } from './stores/session';
import { AdminUserModalComponent } from './features/admin-users';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const MATERIAL = [
  MatAutocompleteModule,
  MatTabsModule,
  MatButtonToggleModule,
  MatButtonModule,
  MatChipsModule,
  MatSelectModule,
  MatDialogModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatInputModule,
  MatFormFieldModule,
  MatSlideToggleModule,
];

const COMPONENTS = [
  AppComponent,
  AutoUnsubscribeComponent,
  ChevronToggleComponent,
  IconComponent,
  FrameComponent,
  LoginComponent,
  SignupComponent,
  BodymapComponent,
  LineEditorComponent,
  MeridiansComponent,
  SearchComponent,
  PointInfoComponent,
  PointEditComponent,
  KeywordsComponent,
  MainSearchComponent,
  ConfirmationModal,
  ForgotPwdComponent,
  PointListComponent,
  SupportModal,

  AuthPageComponent,
  AccupointsPageComponent,
  AdminHistoryComponent,
  AdminPointHistoryComponent,
  AdminUsersComponent,
  AdminUserModalComponent,
  PwdResetComponent,
];

const DIRECTIVES = [DisabledDirective, LoadingDirective];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES],
  imports: [
    CommonModule,
    BrowserModule,
    ...MATERIAL,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  entryComponents: [ConfirmationModal, AdminUserModalComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private accuPointsStoreService: AccuPointsStoreService, private sessionStore: SessionStore) {}
}
