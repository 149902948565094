import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface  ConfirmationModalData {
  title?: string;
  text?: string;
  buttonPrimary?: string;
  buttonSecondary?: string;
  notesHint?: string;
}

@Component({
    selector: 'av-confirmation-modal',
    templateUrl: './confirmation-modal.html',
    styleUrls: ['./confirmation-modal.scss'],
})
export class ConfirmationModal {
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ConfirmationModal>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData) {
    if (data.notesHint) {
      this.form = this.fb.group({
        notes: ['', [Validators.required]],
      });
    } else {
      this.form = this.fb.group({});
    }
  }

  form: FormGroup;
}