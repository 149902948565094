import { Component } from '@angular/core';
import { Point3 } from 'src/app/models';
import * as THREE from 'three';
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry.js';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';

@Component({
  selector: 'av-line-editor',
  templateUrl: './line-editor.component.html',
  styleUrls: ['./line-editor.component.scss'],
})
export class LineEditorComponent {
  isEditing = false;
  scene: THREE.Scene;
  camera: THREE.Camera;
  model: THREE.Mesh;
  geometry = new THREE.Group();
  linePoints: Point3[] = [];

  init(scene: THREE.Scene, camera: THREE.Camera, model: THREE.Mesh) {
    this.scene = scene;
    this.camera = camera;
    this.model = model;
    scene.add(this.geometry);
  }

  click(point: Point3) {
    if (this.isEditing) {
      const lastClick = this.linePoints.length > 0 ? this.linePoints[this.linePoints.length - 1] : null;
      if (lastClick) {
        this.addDecalLine(lastClick, point);
      }
      this.linePoints.push(point);
    }
  }

  onClear() {
    this.geometry.clear();
    this.linePoints = [];
  }
  onRemoveLastLine() {
    this.geometry.remove(this.geometry.children[this.geometry.children.length - 1]);
    this.linePoints.splice(this.linePoints.length - 1, 1);
  }
  save() {
    const finalGeometry = new THREE.Geometry();
    this.geometry.children.forEach(childGroup => {
      const mesh = childGroup.children[0] as THREE.Mesh;
      const geom = new THREE.Geometry().fromBufferGeometry( mesh.geometry as DecalGeometry )
      finalGeometry.merge(geom);
    });


    const material = new THREE.MeshPhongMaterial({
      specular: 0x444444,
      shininess: 30,
      transparent: true,
      depthTest: true,
      depthWrite: false,
      wireframe: false,
      color: 0xff,
      polygonOffset: true,
      polygonOffsetFactor: -4,
    });
    const finalMesh = new THREE.Mesh(finalGeometry, material);
    this.scene.add(finalMesh);

    const exporter = new GLTFExporter();
    exporter.parse( finalMesh, gltf => {
      console.log(JSON.stringify(gltf));
    }, {} );
    // console.log(JSON.stringify(finalMesh.toJSON()));
    this.onClear();
  }

  private addDecalLine(inp1: Point3, inp2: Point3) {
    const decalMaterial = new THREE.MeshPhongMaterial({
      specular: 0x444444,
      shininess: 30,
      transparent: true,
      depthTest: true,
      depthWrite: false,
      wireframe: false,
      color: 0xff0000,
      polygonOffset: true,
      polygonOffsetFactor: -4,
    });

    const point1 = this.vectorFromPoint3(inp1);
    const point2 = this.vectorFromPoint3(inp2);

    // create orientation matrix where Y axis aligned along the line between 2 points
    const yAxis = point2.clone().sub(point1);
    const len = yAxis.length();
    yAxis.normalize();

    const midPoint = this.midPoint(point1, point2);
    const cameraMidPoint = this.camera.position.clone();
    const mtx = new THREE.Matrix4();
    mtx.lookAt(cameraMidPoint, midPoint, yAxis);
    const decalOrient = new THREE.Euler();
    decalOrient.setFromRotationMatrix(mtx);

    const zAxis = new THREE.Vector3(0, 0, 1).applyMatrix4(mtx);
    const dist1 = Math.abs(point1.clone().sub(cameraMidPoint).dot(zAxis));
    const dist2 = Math.abs(point2.clone().sub(cameraMidPoint).dot(zAxis));
    const maxZ = Math.max(dist1, dist2);
    const decalSize = new THREE.Vector3(1, len, maxZ);

    const group = new THREE.Group();
    this.geometry.add(group);

    const decalPoint = midPoint.clone().add(zAxis.clone().multiplyScalar((maxZ - Math.abs(dist1 - dist2)) / 2 - 1));
    const m = new THREE.Mesh(
      new DecalGeometry(this.model, decalPoint, decalOrient, decalSize),
      decalMaterial,
    );
    group.add(m);

    // const box = new THREE.Box3();
    // box.setFromCenterAndSize(decalPoint, decalSize);
    // const boxMesh = new THREE.Box3Helper(box);
    // boxMesh.applyMatrix4(mtx);
    // group.add(boxMesh);

    // group.add(this.buildPoint(midPoint));
    // group.add(this.buildPoint(point1));
    // group.add(this.buildPoint(point2));
  }

  private vectorFromPoint3(pos: Point3): THREE.Vector3 {
    return new THREE.Vector3(pos.x, pos.y, pos.z);
  }

  // returns new point between 2 given points
  private midPoint(point1: THREE.Vector3, point2: THREE.Vector3): THREE.Vector3 {
    return point1.clone().add(point2.clone()).multiplyScalar(0.5);
  }

  private buildPoint(pos: THREE.Vector3): THREE.Mesh {
    const geometry = new THREE.SphereGeometry(1);
    const material = new THREE.MeshBasicMaterial({ color: 0xff });
    const sphere = new THREE.Mesh(geometry, material);
    sphere.position.set(pos.x, pos.y, pos.z);

    return sphere;
  }

  private buildLine(from: THREE.Vector3, to: THREE.Vector3): THREE.Line {
    const points = [from, to];
    const geometry = new THREE.BufferGeometry().setFromPoints( points );
    const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    return new THREE.Line( geometry, material );
  }
}
