import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'av-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(private fb: FormBuilder, private auth: AuthService) {}

  @Output() postLogin = new EventEmitter();
  @Output() gotoSignup = new EventEmitter();
  @Output() gotoForgotPwd = new EventEmitter();

  form = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', [Validators.required]],
  });
  loading = false;
  error: string;

  onSubmit() {
    this.error = null;
    this.loading = true;

    this.auth.login(this.form.value.email, this.form.value.password).subscribe(
      () => {
        this.loading = false;
        this.postLogin.next();
      },
      (error) => {
        this.loading = false;
        this.error = error.code === 'NOT_FOUND' ? 'User with this email or password was not found' : error.message;
      },
    );
  }
}
