<form class="av-login" action="" [formGroup]="form">
  <div class="form-row">
    <input formControlName="email" type="text" placeholder="Enter your email" autofocus>
  </div>
  <div class="form-row form-row-password">
    <input formControlName="password" type="password" placeholder="Enter your password">
  </div>
  <div class="av-login__forgot-pwd">Forgot password? Click <a class="av-link" (click)="gotoForgotPwd.next()">here</a></div>
  <div class="form-error">
    {{error}}
  </div>
  <div class="form-row__submit" [avLoading]="loading">
    <div class="av-login__register">Don't have account? <span class="av-link" (click)="gotoSignup.next()">Register here</span></div>
    <button mat-raised-button color="primary" [disabled]="form.invalid" (click)="onSubmit()">Submit</button>
  </div>
</form>
