<h1 *ngIf="data.title" mat-dialog-title>{{data.title}}</h1>
<form action="" [formGroup]="form" class="point-edit">
  <div mat-dialog-content>
    <p>{{data.text}}</p>
    <mat-form-field *ngIf="data.notesHint">
      <mat-label>{{data.notesHint}}</mat-label>
      <textarea rows="3" matInput formControlName="notes" required></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="action-row">
    <button *ngIf="data.buttonSecondary" mat-button (click)="dialogRef.close()">{{data.buttonSecondary}}</button>
    <button *ngIf="data.buttonPrimary" mat-button [disabled]="!form.valid" [mat-dialog-close]="data.notesHint ? form.value.notes : true" cdkFocusInitial>{{data.buttonPrimary}}</button>
  </div>
</form>