import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RequestParameters } from '../models';

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete' | 'head';

const formatParams = (rawParams: RequestParameters) =>
  ({
    ...(rawParams.body && { body: rawParams.body }),
    ...{
      ...rawParams.httpConfig,
      ...(!!rawParams.queryParams && { params: rawParams.queryParams }),
    },
    ...rawParams.httpConfig,
  } as RequestParameters);

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  get<T = unknown>(url: string, params?: RequestParameters): Observable<T> {
    return this.makeRequest('get', url, params);
  }

  patch<T = unknown>(url: string, params?: RequestParameters): Observable<T> {
    return this.makeRequest('patch', url, params);
  }

  put<T = unknown>(url: string, params?: RequestParameters): Observable<T> {
    return this.makeRequest('put', url, params);
  }

  post<T = unknown>(url: string, params?: RequestParameters): Observable<T> {
    return this.makeRequest('post', url, params);
  }

  head<T = unknown>(url: string, params?: RequestParameters): Observable<T> {
    return this.makeRequest('head', url, params);
  }

  postPlainText<T = unknown>(url: string, params?: RequestParameters): Observable<T> {
    const formattedParams = {
      ...params,
      httpConfig: {
        headers: { 'Content-Type': 'text/plain' },
      },
    };
    return this.makeRequest('post', url, formattedParams);
  }

  delete<T = unknown>(url: string, params?: RequestParameters): Observable<T> {
    return this.makeRequest('delete', url, params);
  }

  private makeRequest(method: HttpMethod, url: string, params: RequestParameters = {}): Observable<any> {
    return this.http.request(method, url, formatParams(params)).pipe(
      catchError((error: unknown) => {
        return throwError(error['error'] || error);
      }),
    );
  }
}
