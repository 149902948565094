import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { AutoUnsubscribeComponent, BodymapClickEvent, BodymapComponent } from 'src/app/components';
import { AccuPoint, BodyLine, BodymapData, MeridianInfo } from 'src/app/models';
import { AuthService, HelperService, PointGroupsService, PointsService } from 'src/app/services';
import { AccuPointsQuery } from 'src/app/stores/points/accu-points.query';
import { AccuPointsStoreService } from 'src/app/stores/points/accu-points.service';
import { ConfirmationModal } from '../../components';

type SearchItemType = 'GROUP' | 'CONDITION';
const colorPalette = [
  0,
  0xff0000,
  0xffff00,
  0xff00ff,
  0x00ff00,
  0x00ffff,
  0x0000ff,
  0xaa0000,
  0xaaaa00,
  0xaa00aa,
  0x00aa00,
  0x00aaaa,
  0x0000aa,
];

@Component({
  selector: 'av-page-accupoints',
  templateUrl: './accupoints.component.html',
  styleUrls: ['./accupoints.component.scss'],
})
export class AccupointsPageComponent extends AutoUnsubscribeComponent implements OnInit {
  @ViewChild(BodymapComponent) bodyMap;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private auth: AuthService,
    private pointsQuery: AccuPointsQuery,
    private pointsStoreService: AccuPointsStoreService,
    private pointsService: PointsService,
    private pointGroupsService: PointGroupsService,
    private helperService: HelperService,
  ) {
    super();
  }

  meridians: MeridianInfo[] = [];
  accuPoints: AccuPoint[] = [];
  activePoint: AccuPoint;
  editPoint = false;
  haveFilters = false;

  bodyData: BodymapData = {
    points: [],
    lines: [],
  };

  tokenInfo = this.auth.getTokenInfo();

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      if (params.groups) {
        const groupIds: number[] = params.groups.split(',').map(id => parseInt(id, 10));
        this.pointGroupsService.getFromIds(groupIds).subscribe(groups => {
          this.pointsStoreService.setFilters(groups);
        });
      }
    });

    this.subscriber = this.pointsQuery.filters$.subscribe((filters) => {
      this.haveFilters = filters.length > 0;
    });

    this.subscriber = combineLatest([this.pointsQuery.meridians$, this.pointsQuery.points$]).subscribe(
      ([meridians, points]) => {
        this.meridians = meridians;
        this.accuPoints = points;
        this.buildBodyData();
      },
    );

    this.pointsQuery.active$.subscribe((point) => {
      this.activePoint = point ? { ...point } : null;
      this.buildBodyData();
    });
  }

  get canUserEdit(): boolean {
    return this.tokenInfo ? this.tokenInfo.role === 'ADMIN' || this.tokenInfo.role === 'EDITOR' : false;
  }

  onSelectPoint(point: AccuPoint) {
    this.pointsStoreService.setActive(point);
    this.editPoint = point && !point.id;
  }

  onEditPoint(point: AccuPoint) {
    this.editPoint = true;
  }
  onDeletePoint(point: AccuPoint) {
    const dialogRef = this.dialog.open(ConfirmationModal, {
      width: '400px',
      data: {
        title: 'Delete Point',
        text: `Are you sure you want to remove point ${point.name}`,
        buttonPrimary: 'Delete',
        buttonSecondary: 'Cancel',
        notesHint: 'Why are you removing this point?',
      },
    });

    dialogRef.afterClosed().subscribe((notes) => {
      if (notes) {
        this.pointsService.delete(point.id, notes).subscribe(() => {
          this.pointsStoreService.delete(point.id);
          this.pointsStoreService.setActive(null);
        });
      }
    });
  }
  onShowPoint(point: AccuPoint) {
    if (point.camera && this.bodyMap) {
      this.bodyMap.moveCamera(point.camera, point.location);
    }
  }
  cancelPointEdit(point: AccuPoint) {
    this.editPoint = false;
    if (!point.id) {
      this.pointsStoreService.setActive(null);
    } else {
      const oldPoint = this.pointsQuery.getEntity(point.id);
      this.pointsStoreService.setActive({ ...oldPoint });
    }
  }
  submitPoint(point: AccuPoint) {
    this.pointsStoreService.upsert(point);
    this.pointsStoreService.setActive(point);
    this.editPoint = false;
  }

  bodymapClick(event: BodymapClickEvent) {
    if (this.activePoint && this.editPoint && event.bodyPoint) {
      this.activePoint.location = event.bodyPoint;
      this.activePoint.camera = event.camera;
      this.buildBodyData();
    } else if (event.bodyPoint) {
      this.pointsStoreService.setActive(event.accuPoint ? event.accuPoint.userData : null);
    }
  }

  openSupportModal() {
    this.helperService.showSupportEmailModal();
  }

  private buildBodyData() {
    this.bodyData = {
      lines: [],
      points: [],
    };

    // add active point
    if (this.activePoint && this.activePoint.location) {
      this.bodyData.points.push({
        point: this.activePoint.location,
        color: 0xff0000,
        label: this.activePoint.name,
        userData: this.activePoint,
      });
    }

    // add rest of the points
    this.accuPoints.forEach((point) => {
      if (point.location && (!this.activePoint || this.activePoint.id !== point.id)) {
        this.bodyData.points.push({
          point: point.location,
          color: 0x0,
          label: point.name,
          userData: point,
        });
      }
    });

    this.meridians.forEach((meridian, index) => {
      const lineData: BodyLine = {
        line: {
          points: [],
        },
        color: colorPalette[index % colorPalette.length],
        label: null,
      };

      meridian.points.forEach((point) => {
        if (point.location) {
          lineData.line.points.push(point.location);
        }
      });
      this.bodyData.lines.push(lineData);
    });
  }
}
