import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { AuthService } from './auth.service';

/**
 * Route guard for authenticated pages
 */
@Injectable()
export class AuthRouteGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    let allow = true;
    if (!route.data || !route.data.public) {
        // route is not public
        const jwt = this.authService.getTokenInfo();
        if (!jwt) {
            allow = false; // user not authenticated
        } else if (route.data && route.data.admin && jwt.role !== 'ADMIN') {
            allow = false; // route ADMIN only but user not admin
        }
    }
    
    if (!allow) {
        window.location.href = '/';
    }
    return allow;
  }
}
