<av-frame>
  <div class="av-auth">
    <div class="av-auth__wrap">
      <mat-tab-group dynamicHeight [selectedIndex]="activeTab">
        <mat-tab>
          <div class="av-auth__header">
            Login
          </div>
          <div class="av-auth__content">
            <av-login (postLogin)="postLogin()" (gotoSignup)="setActiveTab(1)" (gotoForgotPwd)="setActiveTab(2)"></av-login>
          </div>
        </mat-tab>
        <mat-tab>
          <div class="av-auth__header">
            Register
          </div>
          <div class="av-auth__content">
            <av-signup (postSignup)="postSignup()" (gotoLogin)="setActiveTab(0)"></av-signup>
          </div>
        </mat-tab>
        <mat-tab>
          <div class="av-auth__header">
            Forgot my password
          </div>
          <div class="av-auth__content">
            <av-forgot-pwd (goBack)="setActiveTab(0)"></av-forgot-pwd>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</av-frame>

