import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services';

@Component({
  selector: 'av-forgot-pwd',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['./forgot-pwd.component.scss']
})
export class ForgotPwdComponent {

  constructor(private fb: FormBuilder, private auth: AuthService) {}

  @Output() goBack = new EventEmitter();

  form = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
  });
  loading = false;
  error: string;
  successMsg: string;

  onSubmit() {
    this.successMsg = null;
    this.error = null;
    this.loading = true;

    const email = this.form.value.email;
    this.auth.pwdResetRequest(email).subscribe(
      (res) => {
        console.log(res);
        this.loading = false;
        this.successMsg = `Email with password instructions was successfully sent to ${email}`;
      },
      (error) => {
        this.loading = false;
        this.error = error.code === 'NOT_FOUND' ? 'User with this email was not found' : error.message;
      },
    );
  }
}
