import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SupportModal } from "../components/support-modal/support-modal.component";

@Injectable({
  providedIn: 'root',
})
export class HelperService {

  constructor(public dialog: MatDialog) {}

  showSupportEmailModal() {
    const dialogRef = this.dialog.open(SupportModal, {width: '600px'});
  }
}
