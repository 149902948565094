<av-frame>
    <div class="users-page">
        <h2>Users</h2>
            
        <div *ngIf="users">
            <table>
                <tr>
                    <th>Email</th>
                    <th>Created</th>
                    <th>Role</th>
                    <th>Status</th>
                </tr>
                <tr *ngFor="let user of users.list" class="users-page-item" (click)="editUser(user)">
                    <td>{{user.email}}</td>
                    <td>{{user.timestamp | date: 'medium'}}</td>
                    <td>{{user.role}}</td>
                    <td [matTooltip]="user.notes">{{user.state}}</td>
                </tr>
            </table>

            <mat-paginator [length]="users.total" [hidePageSize]="true" [pageSize]="pageSize" [pageIndex]="activePage" (page)="loadPage($event.pageIndex)"></mat-paginator>
        </div>
    </div>
</av-frame>

