import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";

export const validators = {
  passwordPattern: (): ValidatorFn => {
      return (control: AbstractControl): { [key: string]: any } => {
          if (!control.value) {
            return null;
          }
    
          return control.value.length > 6 ? null : { pattern: true };
        };      
  },
  matchValue: (value: string): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
          return null;
        }
  
        return control.value === value ? null : { matchValue: true };
    };      
  },
  fieldsMatch: (controlName: string, matchingControlName: string) => {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
      
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
              // return if another validator has already found an error on the matchingControl
              return;
            }
      
            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
              matchingControl.setErrors({ mustMatch: true });
            } else {
              matchingControl.setErrors(null);
            }
        };
    }
};