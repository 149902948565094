import { QueryEntity } from '@datorama/akita';
import { Inject, Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { AccuPointsState } from './points-state.model';
import { AccuPointsStore } from './accu-points.store';
import { PointsService } from 'src/app/services';
import { AccuPointsStoreService } from './accu-points.service';

@Injectable({ providedIn: 'root' })
export class AccuPointsQuery extends QueryEntity<AccuPointsState> {
  constructor(
    @Inject(AccuPointsStore) protected store: AccuPointsStore,
    private pointsService: PointsService,
    private accuPointsStoreService: AccuPointsStoreService,
  ) {
    super(store);
  }
  points$ = this.selectAll();
  filters$ = this.select((state) => state.filters);
  meridians$ = this.select((state) => state.meridians);

  // currently active point
  active$ = this.select((state) => state.active).pipe(
    filter((activePoint) => {
      if (!activePoint || activePoint.description !== undefined || !activePoint.id) {
        return true;
      }

      this.pointsService.get(activePoint.id).subscribe((dbPoint) => {
        this.accuPointsStoreService.upsert(dbPoint);
        this.accuPointsStoreService.setActive(dbPoint);
      });

      return false;
    }),
  );
}
