import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AccuPoint, AccuPointGroup } from 'src/app/models';
import { PointGroupsService, PointsService } from 'src/app/services';
import { AutoUnsubscribeComponent } from '../auto-unsubscribe/auto-unsubscribe.component';

@Component({
  selector: 'av-point-edit',
  templateUrl: './point-edit.component.html',
  styleUrls: ['./point-edit.component.scss'],
})
export class PointEditComponent extends AutoUnsubscribeComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private pointsService: PointsService,
    private pointGroupsService: PointGroupsService,
  ) {
    super();
  }

  _data: AccuPoint;
  @Input() set data(inp: AccuPoint) {
    this._data = inp;
    this.meridians = inp.meridians ? [...inp.meridians] : [];
    this.keywords = inp.groups ? [...inp.groups] : [];

    this.form.patchValue({
      name: inp.name,
      label: inp.label,
      description: inp.description,
    });
  }

  meridians: AccuPointGroup[] = [];
  filteredMeridians: AccuPointGroup[] = [];

  keywords: AccuPointGroup[] = [];
  filteredKeywords: AccuPointGroup[] = [];

  @Output() cancel = new EventEmitter<AccuPoint>();
  @Output() afterSubmit = new EventEmitter<AccuPoint>();

  form = this.fb.group({
    name: ['', [Validators.required]],
    label: [''],
    description: [''],
  });
  loading = false;
  error: string;

  ngOnInit() {
    this.filterMeridian();
    this.filterKeyword();
  }

  onSubmit() {
    const newData = {
      ...this._data,
      name: this.form.value.name,
      label: this.form.value.label,
      description: this.form.value.description,
      meridians: this.meridians,
      groups: this.keywords,
    };

    this.loading = true;
    this.error = null;
    const obs = newData.id ? this.pointsService.update(newData) : this.pointsService.create(newData);
    obs
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((point) => {
        setTimeout(() => {
          this.afterSubmit.next(point);
        });
      },
      error => {
        this.error = error.code === 'ER_DUP_ENTRY' ? 'Point with that name already exists' : 'Unexpected error';
      });
  }

  filterMeridian(term = '') {
    const groupIds = this.meridians.map((group) => group.id);
    this.pointGroupsService.getAll(term, 'GROUP', groupIds).subscribe((results) => {
      this.filteredMeridians = results;
    });
  }
  filterKeyword(term = '') {
    const groupIds = this.keywords.map((group) => group.id);
    this.pointGroupsService.getAll(term, 'CONDITION', groupIds, 10).subscribe((results) => {
      this.filteredKeywords = results;
    });
  }
}
