import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { BodymapData, BodyPoint, Point2, Point3 } from 'src/app/models';

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2.js';
import { ObjectAnimator } from './object-animator';
import { MouseSelector } from './mouse-selector.model';
import { LineEditorComponent } from './components';
import { Vector2 } from 'three';

export interface BodymapClickEvent {
  bodyPoint: Point3;
  accuPoint: BodyPoint;
  camera: Point3;
}

interface ModelInfo {
  file: string;
  model?: THREE.Object3D; // geometry
  box?: THREE.Box3;
}
type ViewType = 'skin' | 'muscles';

@Component({
  selector: 'av-bodymap',
  templateUrl: './bodymap.component.html',
  styleUrls: ['./bodymap.component.scss'],
})
export class BodymapComponent implements AfterViewInit {
  @ViewChild('main') private main: ElementRef;
  @ViewChild(LineEditorComponent) private lineEditor: LineEditorComponent;

  constructor() {}

  _data: BodymapData;
  @Input() set data(inp: BodymapData) {
    this._data = inp;
    this.buildBodyData();
  }
  @Input() showLineEdit = false;

  @Output() mouseClick = new EventEmitter<BodymapClickEvent>();

  materials: { [key: string]: any } = {
    default: new THREE.MeshLambertMaterial({
      color: 0xffe0bd,
    }),
  };
  models: { [key: string]: ModelInfo } = {
    male: {
      file: 'test',
    },
  };

  mouseDownPos: Point2;
  loading = false;
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  renderer: THREE.WebGLRenderer;
  controls: OrbitControls;
  mouseSelector = new MouseSelector();
  objectAnimator = new ObjectAnimator();

  dataGeometry = new THREE.Group();
  _viewType: ViewType = 'skin';
  get viewType(): ViewType {
    return this._viewType;
  }
  set viewType(inp: ViewType) {
    this._viewType = inp;
    this.models.male.model.children.forEach((obj, index) => {
      obj.visible = (this._viewType === 'skin' && index < 2) || (this._viewType === 'muscles' && index >= 2);
    });
  }

  ngAfterViewInit() {
    const parent = this.main.nativeElement;
    this.mouseSelector.domElem = parent;

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x777777);
    this.scene.add(this.dataGeometry);

    this.camera = new THREE.PerspectiveCamera(55, parent.clientWidth / parent.clientHeight, 0.1, 10);
    this.camera.up = new THREE.Vector3(0, 0, 1);
    this.camera.position.set(0, 180, 0);
    this.scene.add(this.camera);

    this.renderer = new THREE.WebGLRenderer();
    parent.appendChild(this.renderer.domElement);
    this.onWindowResize();

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    const ambLight = new THREE.AmbientLight(0x707070); // soft white light
    this.scene.add(ambLight);
    const light = new THREE.PointLight(0xeeeeee, 0.6);
    light.position.set(0, 0, 0);
    this.camera.add(light);

    // const axesHelper = new THREE.AxesHelper(150);
    // this.scene.add(axesHelper);

    this.loading = true;
    this.loadModels().subscribe(() => {
      this.loading = false;

      const maxSize = this.getModelSize(this.models.male);
      this.camera.far = maxSize * 10;
      this.camera.near = maxSize / 100;

      this.controls.minDistance = 0;
      this.controls.maxDistance = this.camera.far - maxSize;
      this.controls.zoomSpeed = 1;
      this.onResetCamera();

      this.scene.add(this.models.male.model);
      this.buildBodyData();

      if (this.lineEditor) {
        this.lineEditor.init(this.scene, this.camera, this.models.male.model.children[0] as THREE.Mesh);
      }
    });

    this.animate();
  }

  onChangeView(event) {
    this.viewType = event.value;
  }

  @HostListener('window:resize')
  onWindowResize() {
    const parent = this.main.nativeElement;
    this.camera.aspect = parent.clientWidth / parent.clientHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(parent.clientWidth, parent.clientHeight);
  }

  onMouseDown(event) {
    this.mouseDownPos = this.getMousePos(event);
  }
  onMouseUp(event) {
    if (this.mouseDownPos) {
      const mousePos = this.getMousePos(event);
      if (Math.abs(this.mouseDownPos.x - mousePos.x) < 2 && Math.abs(this.mouseDownPos.y - mousePos.y) < 2) {
        const clickEvent = this.pickEventFromMouse(mousePos.x, mousePos.y);
        const outEvent = {
          camera: this.getCameraPosition(),
          bodyPoint: clickEvent.bodyPoint,
          accuPoint: clickEvent.accuPoint,
        } as BodymapClickEvent;

        this.mouseClick.next(outEvent);

        if (outEvent.bodyPoint && this.lineEditor) {
          this.lineEditor.click(outEvent.bodyPoint);
        }
      }
      this.mouseDownPos = null;
    }
  }

  onResetCamera(animate = true) {
    const maxSize = this.getModelSize(this.models.male);

    if (animate) {
      this.objectAnimator.setup(
        this.camera,
        [this.camera.position, new THREE.Vector3(0, maxSize, 0)],
        new THREE.Vector3(0, 0, 0),
        0.3,
      );
    } else {
      this.camera.position.set(0, maxSize, 0);
    }
    this.controls.reset();
  }
  onShowMesh(type: 'skin' | 'muscles') {}

  moveCamera(point: Point3, target: Point3) {
    this.objectAnimator.setup(
      this.camera,
      [this.camera.position, new THREE.Vector3(-Math.abs(point.x), point.y, point.z)],
      new THREE.Vector3(-Math.abs(target.x), target.y, target.z),
      1,
    );

    // this.camera.position.set(point.x, point.y, point.z);
    // this.camera.lookAt(target.x, target.y, target.z);
    this.controls.target.set(target.x, target.y, target.z);
  }

  // onMouseMove(event) {
  //   this.mouseSelector.onMouseMove(this.getMousePos(event));
  // }

  private animate() {
    this.objectAnimator.render();
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.animate.bind(this));
  }
  private getCameraPosition(): Point3 {
    return {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
  }
  private getMousePos(event): Point2 {
    const rect = this.main.nativeElement.getBoundingClientRect();
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };
  }
  // returns biggest box size
  private getModelSize(model: ModelInfo): number {
    const box = model.box;
    return Math.max(box.max.x - box.min.x, Math.max(box.max.y - box.min.y, box.max.z - box.min.z));
  }
  private pickEventFromMouse(x: number, y: number): BodymapClickEvent {
    const outEvent = {
      camera: this.getCameraPosition(),
    } as BodymapClickEvent;

    let closestDist = 0;
    let closestObj = null;
    const pos3d = new THREE.Vector3();
    this.dataGeometry.children.forEach(child => {
      child.getWorldPosition(pos3d);
      const pos2d = this.getScreenFromVector(pos3d);
      const dist = Math.sqrt((pos2d.x - x) * (pos2d.x - x) + (pos2d.y - y) * (pos2d.y - y));
      if (dist < 10) {
        if (!closestObj || closestDist > dist) {
          closestObj = child;
          closestDist = dist;
        }
      }
    });

    if (closestObj) {
      outEvent.accuPoint = closestObj.userData.point;
    }

    const mousePos = { x, y };
    const bodyPoint = this.mouseSelector.pickMesh(mousePos, this.camera, [
      this.models.male.model.children[0],
      this.models.male.model.children[2],
    ]);
    if (bodyPoint) {
      outEvent.bodyPoint = bodyPoint.point;
    }

    return outEvent;
  }
  private getScreenFromVector(objPos: THREE.Vector3): Point2 {
    const parent = this.main.nativeElement;
    const halfWidth = parent.clientWidth * 0.5;
    const halfHeight = parent.clientHeight * 0.5;

    const pos = objPos.clone();
    pos.project(this.camera);

    return {
      x: ( pos.x * halfWidth ) + halfWidth,
      y: - ( pos.y * halfHeight ) + halfHeight
    };
  }

  private loadModels(): Observable<void> {
    return new Observable<void>((observer) => {
      const url = `${window.location.protocol}//${window.location.host}`;

      const models = Object.values(this.models);
      let totalFinished = 0;
      models.forEach((info) => {
        const objLoader = new OBJLoader2();
        objLoader.load(`${url}/assets/meshes/${info.file}.obj`, (obj: any) => {
          const materialSkin = new THREE.MeshPhongMaterial({
            color: 0xffe0bd,
            shininess: 0,
            normalMap: new THREE.TextureLoader().load(
              `${url}/assets/meshes/textures/MaleAnatomySkin_normal_base_small.png`,
            ),
          });
          const materialMuscles = new THREE.MeshPhongMaterial({
            color: 0xfc6464,
            shininess: 50,
            normalMap: new THREE.TextureLoader().load(`${url}/assets/meshes/textures/MaleAnatomyEcorche_Normal.png`),
          });

          obj.children[0].material = materialSkin;
          obj.children[2].material = materialMuscles;
          obj.children[2].visible = false;
          obj.children[3].visible = false;

          const mesh = obj; // new THREE.Mesh(obj.children[0].geometry, materialSkin);
          mesh.rotateX(3.14 / 2);
          mesh.rotateY(3.14);
          info.box = new THREE.Box3().setFromObject(mesh);
          mesh.position.set(
            -(info.box.max.x + info.box.min.x) * 0.5,
            (info.box.max.y + info.box.min.y) * 0.5,
            -(info.box.max.z + info.box.min.z) * 0.5,
          );

          info.model = mesh;

          // show bounding box
          // const box = new THREE.BoxHelper(mesh, 0xff0000);
          // this.scene.add(box);

          totalFinished++;
          if (totalFinished === models.length) {
            observer.next();
          }
        });
      });
    });
  }

  private buildBodyData() {
    this.dataGeometry.clear();

    if (this._data && this.models.male.box) {
      const maxSize = this.getModelSize(this.models.male);

      this._data.points.forEach((point) => {
        const geometry = new THREE.SphereGeometry(maxSize * 0.002);
        const material = new THREE.MeshBasicMaterial({ color: point.color });
        const sphere = new THREE.Mesh(geometry, material);
        sphere.position.set(-Math.abs(point.point.x), point.point.y, point.point.z);
        sphere.userData = { point };
        this.dataGeometry.add(sphere);
      });

      // this._data.lines.forEach((line) => {
      //   const material = new THREE.LineBasicMaterial({ color: line.color });
      //   const points = [];
      //   line.line.points.forEach(point => {
      //     points.push(new THREE.Vector3( -Math.abs(point.x), point.y, point.z));
      //   });
      //   const geometry = new THREE.BufferGeometry().setFromPoints( points );
      //   const threeLine = new THREE.Line( geometry, material );
      //   this.dataGeometry.add( threeLine );
      // });
    }
  }
}
